@font-face {
    font-family: 'Kross Neue Grotesk';
    src: url('/public/assets/fonts/KrossNeueGrotesk-Regular/KrossNeueGrotesk-Regular.eot');
    src: local('Kross Neue Grotesk Regular'), local('KrossNeueGrotesk-Regular'),
        url('/public/assets/fonts/KrossNeueGrotesk-Regular/KrossNeueGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Regular/KrossNeueGrotesk-Regular.woff2') format('woff2'),
        url('/public/assets/fonts/KrossNeueGrotesk-Regular/KrossNeueGrotesk-Regular.woff') format('woff'),
        url('/public/assets/fonts/KrossNeueGrotesk-Regular/KrossNeueGrotesk-Regular.ttf') format('truetype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Regular/KrossNeueGrotesk-Regular.svg#KrossNeueGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kross Neue Grotesk';
    src: url('/public/assets/fonts/KrossNeueGrotesk-Italic/KrossNeueGrotesk-Italic.eot');
    src: local('Kross Neue Grotesk Italic'), local('KrossNeueGrotesk-Italic'),
        url('/public/assets/fonts/KrossNeueGrotesk-Italic/KrossNeueGrotesk-Italic.eot?#iefix') format('embedded-opentype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Italic/KrossNeueGrotesk-Italic.woff2') format('woff2'),
        url('/public/assets/fonts/KrossNeueGrotesk-Italic/KrossNeueGrotesk-Italic.woff') format('woff'),
        url('/public/assets/fonts/KrossNeueGrotesk-Italic/KrossNeueGrotesk-Italic.ttf') format('truetype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Italic/KrossNeueGrotesk-Italic.svg#KrossNeueGrotesk-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kross Neue Grotesk';
    src: url('/public/assets/fonts/KrossNeueGrotesk-Light/KrossNeueGrotesk-Light.eot');
    src: local('Kross Neue Grotesk Light'), local('KrossNeueGrotesk-Light'),
        url('/public/assets/fonts/KrossNeueGrotesk-Light/KrossNeueGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Light/KrossNeueGrotesk-Light.woff2') format('woff2'),
        url('/public/assets/fonts/KrossNeueGrotesk-Light/KrossNeueGrotesk-Light.woff') format('woff'),
        url('/public/assets/fonts/KrossNeueGrotesk-Light/KrossNeueGrotesk-Light.ttf') format('truetype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Light/KrossNeueGrotesk-Light.svg#KrossNeueGrotesk-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Kross Neue Grotesk';
    src: url('/public/assets/fonts/KrossNeueGrotesk-Book/KrossNeueGrotesk-Book.eot');
    src: local('Kross Neue Grotesk Book'), local('KrossNeueGrotesk-Book'),
        url('/public/assets/fonts/KrossNeueGrotesk-Book/KrossNeueGrotesk-Book.eot?#iefix') format('embedded-opentype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Book/KrossNeueGrotesk-Book.woff2') format('woff2'),
        url('/public/assets/fonts/KrossNeueGrotesk-Book/KrossNeueGrotesk-Book.woff') format('woff'),
        url('/public/assets/fonts/KrossNeueGrotesk-Book/KrossNeueGrotesk-Book.ttf') format('truetype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Book/KrossNeueGrotesk-Book.svg#KrossNeueGrotesk-Book') format('svg');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kross Neue Grotesk';
    src: url('/public/assets/fonts/KrossNeueGrotesk-Bold/KrossNeueGrotesk-Bold.eot');
    src: local('Kross Neue Grotesk Bold'), local('KrossNeueGrotesk-Bold'),
        url('/public/assets/fonts/KrossNeueGrotesk-Bold/KrossNeueGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Bold/KrossNeueGrotesk-Bold.woff2') format('woff2'),
        url('/public/assets/fonts/KrossNeueGrotesk-Bold/KrossNeueGrotesk-Bold.woff') format('woff'),
        url('/public/assets/fonts/KrossNeueGrotesk-Bold/KrossNeueGrotesk-Bold.ttf') format('truetype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Bold/KrossNeueGrotesk-Bold.svg#KrossNeueGrotesk-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kross Neue Grotesk';
    src: url('/public/assets/fonts/KrossNeueGrotesk-Heavy/KrossNeueGrotesk-Heavy.eot');
    src: local('Kross Neue Grotesk Heavy'), local('KrossNeueGrotesk-Heavy'),
        url('/public/assets/fonts/KrossNeueGrotesk-Heavy/KrossNeueGrotesk-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Heavy/KrossNeueGrotesk-Heavy.woff2') format('woff2'),
        url('/public/assets/fonts/KrossNeueGrotesk-Heavy/KrossNeueGrotesk-Heavy.woff') format('woff'),
        url('/public/assets/fonts/KrossNeueGrotesk-Heavy/KrossNeueGrotesk-Heavy.ttf') format('truetype'),
        url('/public/assets/fonts/KrossNeueGrotesk-Heavy/KrossNeueGrotesk-Heavy.svg#KrossNeueGrotesk-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Minion Pro';
    src: url('/public/assets/fonts/MinionPro-Italic/MinionPro-It.eot');
    src: local('Minion Pro Italic'), local('MinionPro-It'),
        url('/public/assets/fonts/MinionPro-Italic/MinionPro-It.eot?#iefix') format('embedded-opentype'),
        url('/public/assets/fonts/MinionPro-Italic/MinionPro-It.woff2') format('woff2'),
        url('/public/assets/fonts/MinionPro-Italic/MinionPro-It.woff') format('woff'),
        url('/public/assets/fonts/MinionPro-Italic/MinionPro-It.ttf') format('truetype'),
        url('/public/assets/fonts/MinionPro-Italic/MinionPro-It.svg#MinionPro-It') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}