.react-tel-input > .form-control {
    color: #000000;
    border: none;
    border-bottom: 1px solid #707070;
    font-family: 'Kross Neue Grotesk';
    font-weight: bold;
    font-style: normal;
    font-size: .8rem;
    border-radius: initial;
    width: 100%;
    outline: initial;
}

.react-tel-input > .form-control:focus {
    background-color: initial;
    border-color: #707070;
    outline: auto;
    box-shadow: none;
}

.react-tel-input > .selected-flag:focus {
    background-color: initial;
    border-color: #707070;
    outline: auto;
    box-shadow: none;
}

.react-tel-input .selected-flag:focus:before {
    border: none;
    box-shadow: none;
}

.react-tel-input .selected-flag.open:before {
    border-color: #000000;
    box-shadow: none;
}